import Axios from "../api";
import * as types from "./../constants/types";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";

const fetchWishListLoading = () => ({
  type: types.FETCH_WISHLIST_ITEMS_LOADING,
});

const fetchWishListSuccess = (data) => ({
  type: types.FETCH_WISHLIST_ITEMS_SUCCESS,
  payload: data,
});

const fetchWishListFailure = (error) => ({
  type: types.FETCH_WISHLIST_ITEMS_FAILURE,
  payload: error,
});

// add to wishlist
export const addToWishlist = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Added To Wishlist", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};

// delete from wishlist
export const deleteFromWishlist = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed From Wishlist", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromWishlist = (addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed All From Wishlist", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};

//update wushlist items in cloud
export const updateWishlistItems = (products) => (dispatch) => {
  const data = { products };
  const url = `/wishlist/update`;
  Axios.post(url, data)
    .then((res) => {})
    .catch((res) => {});
};

//fetch wishlist
export const fetchWishlistItems = () => (dispatch) => {
  const url = `/wishlist/list`;
  dispatch(fetchWishListLoading());
  Axios.get(url)
    .then((res) => {
      dispatch(fetchWishListSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchWishListFailure(err));
    });
};
