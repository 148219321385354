import axios from "axios";
import { getLocalState } from "../helpers/localStorage";

var apiInstace = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: getLocalState("token"),
  },
});

export default apiInstace;
