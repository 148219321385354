import {
  ADD_TO_WISHLIST,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
} from "../actions/wishlist.actions";
import * as types from "./../constants/types";

const initState = {
  items: [],
  loading: false,
  error: false,
  success: false,
};

const wishlistReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case DELETE_FROM_WISHLIST:
      return {
        ...state,
        items: state.items.filter((o) => o._id !== action.payload._id),
      };
    case DELETE_ALL_FROM_WISHLIST:
      return {
        ...state,
        items: [],
      };
    case types.FETCH_WISHLIST_ITEMS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.FETCH_WISHLIST_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        success: true
      };
    case types.FETCH_WISHLIST_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default wishlistReducer;
