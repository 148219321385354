import * as types from '../constants/types';
import Axios from '../api/index'


const fetchCategoryLoading = () => ({
    type: types.FETCH_CATEGORY_LIST_LOADING
});

const fetchCategorySuccess = (data) => ({
    type: types.FETCH_CATEGORY_LIST_SUCCESS,
    payload: data
});

const fetchCategoryFailure = error => ({
    type: types.FETCH_CATEGORY_LIST_FAILURE,
    paylod: error
});

export const selectCategory = (category)  => ({
    type:types.SELECTED_CATEGORY,
    payload:category
})


export const fetchCategories = () => dispatch => {
    dispatch(fetchCategoryLoading());
    const url = '/categories/list';
    return Axios.get(url)
    .then(res => {
        dispatch(fetchCategorySuccess(res.data));
        return res; 
    })
    .catch(err => {
        dispatch(fetchCategoryFailure(err));
        throw err;
    })
}

