import * as types from "./../constants/types";
import Axios from "../api";

export const clearLoadingState = () => ({
  type: types.CLEAR_LOADING_STATE,
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER,
});

//SIGNUP
const signupLoading = () => ({
  type: types.SIGNUP_STARTED,
});

const signupSuccess = (data) => ({
  type: types.SIGNUP_SUCCESS,
  payload: data,
});

const signupFailure = (error) => ({
  type: types.SIGNUP_FAILURE,
  payload: error,
});

//LOGIN
const loginLoading = () => ({
  type: types.LOGIN_STARTED,
});

const loginSuccess = (data) => ({
  type: types.LOGIN_SUCCESS,
  payload: data,
});

const loginFailure = (error) => ({
  type: types.LOGIN_FAILURE,
  payload: error,
});

//FETCH PROFILE
const fetchUserProfileLoading = () => ({
  type: types.FETCH_PROFILE_LOADING,
});

const fetchUserProfileSuccess = (data) => ({
  type: types.FETCH_PROFILE_SUCCESS,
  payload: data,
});

const fetchUserProfileFailure = (error) => ({
  type: types.FETCH_PROFILE_FAILURE,
  payload: error,
});

//UPDATE PROFILE
const updateUserProfileLoading = () => ({
  type: types.UPDATE_PROFILE_LOADING,
});

// const updateUserProfileSuccess = (data) => ({
//   type: types.UPDATE_PROFILE_SUCCESS,
//   payload: data,
// });

const updateUserProfileFailure = (error) => ({
  type: types.UPDATE_PROFILE_FAILURE,
  payload: error,
});

export const selectAddress = (address) => ({
  type: types.SELECTED_ADDRESS,
  payload: address,
});

export const signupUser = (bodyParams) => (dispatch) => {
  dispatch(signupLoading());
  const url = `/users/signup`;
  return Axios.post(url, bodyParams)
    .then((res) => {
      Axios.defaults.headers.Authorization = res.data.token;
      dispatch(signupSuccess(res.data));
      return res;
    })
    .catch((err) => {
      dispatch(signupFailure(err?.response?.data?.message || 'Sorry. something happened. Please try later'));
      // dispatch(signupFailure(err));
      throw err;
    });
};

export const loginUser = (bodyParams) => (dispatch) => {
  dispatch(loginLoading());
  const url = `/users/signin/customer`;
  return Axios.post(url, bodyParams)
    .then((res) => {
      Axios.defaults.headers.Authorization = res.data.token;
      dispatch(loginSuccess(res.data));
      return res;
    })
    .catch((err) => {
      console.log(err);

      dispatch(loginFailure(err?.response?.data?.message || 'Invalid Credentials'));
      throw err;
    });
};

export const fetchUserProfile = () => (dispatch) => {
  dispatch(fetchUserProfileLoading());
  const url = `/users/profile`;
  Axios.get(url)
    .then((res) => {
      dispatch(fetchUserProfileSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchUserProfileFailure(err));
    });
};

export const updateUserProfile = (profileData) => (dispatch) => {
  dispatch(updateUserProfileLoading());
  const url = `/users/profile/update`;
  return Axios.put(url, profileData)
    .then((res) => {
      dispatch(fetchUserProfile());
      return res;
    })
    .catch((err) => {
      dispatch(updateUserProfileFailure(err));
      throw err;
    });
};
