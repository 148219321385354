import * as types from "../constants/types";

const INITIAL_STATE = {
  loading: false,
  authorList: [],
  error: null,
  page: {},
  selectedAuthor: null,
};

const AuthorReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FETCH_AUTHOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        authorList: [],
        error: null,
      };
    case types.FETCH_AUTHOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        page: payload.page,
        authorList: payload.authors,
        error: null,
      };
    case types.FETCH_AUTHOR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        authorList: [],
        error: payload,
      };
    // case types.SELECTED_AUTHOR:
    //   return {
    //     ...state,
    //     selectedAuthor: payload,
    //   };

    default:
      return state;
  }
};

export default AuthorReducer;
