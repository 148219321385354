import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { fetchAuthors } from "./actions/authors.actions";
import { fetchCategories } from "./actions/categories.actions";
import { fetchUserProfile } from "./actions/auth.actions";
import { fetchPublishers } from "./actions/publishers.actions";
import ReactGa from 'react-ga';

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

//offers page

const Offers = lazy(() => import("./pages/offers/offers"));
// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Tracking = lazy(() => import("./pages/other/Tracking"));
const Catalogue = lazy(() => import("./pages/catalogue/Catalogue"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const OrderReceived = lazy(() => import("./pages/other/Order-received"));

const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));

const MyEbooks = lazy(() => import("./pages/my-ebooks/MyEbooks"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const AuthorsPage = lazy(() => import("./pages/authors/Authors"));
const PublishersPage = lazy(() => import("./pages/publishers/Publishers"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const PrivacyPolicy = lazy(() => import("./components/privacy-policy/PrivacyPolicy"));

const App = (props) => {
  const { dispatch } = props;
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  useEffect(() => {
    dispatch(fetchAuthors());
    dispatch(fetchCategories());
    dispatch(fetchUserProfile());
    dispatch(fetchPublishers());
    ReactGa.initialize('UA-215365653-1');
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, [dispatch]);

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="NBH-preloader-wrapper">
                  <div className="NBH-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route exact path={process.env.PUBLIC_URL + "/"} component={HomeFashion} />
                {/* Shop pages */}
                <Route exact path={process.env.PUBLIC_URL + "/books"} component={ShopGridStandard} />
                <Route path={process.env.PUBLIC_URL + "/book/:id"} component={Product} />
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route exact path={process.env.PUBLIC_URL + "/offers/books/:id"} component={Offers} />
                {/* Other pages */}
                <Route path={process.env.PUBLIC_URL + "/about"} component={About} />
                <Route path={process.env.PUBLIC_URL + "/contact"} component={Contact} />
                <Route path={process.env.PUBLIC_URL + "/tracking"} component={Tracking} />
                <Route path={process.env.PUBLIC_URL + "/catalogue"} component={Catalogue} />

                <Route path={process.env.PUBLIC_URL + "/my-account"} component={MyAccount} />
                <Route path={process.env.PUBLIC_URL + "/my-orders"} component={MyOrders} />
                <Route
                  path={process.env.PUBLIC_URL + "/order-received/:id"}
                  // /product-slider/:id
                  render={(routeProps) => (
                    <OrderReceived {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route path={process.env.PUBLIC_URL + "/login-register"} component={LoginRegister} />

                <Route
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgotPassword}
                />

                <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart} />
                <Route path={process.env.PUBLIC_URL + "/wishlist"} component={Wishlist} />
                <Route path={process.env.PUBLIC_URL + "/downloads/ebooks"} component={MyEbooks} />
                <Route path={process.env.PUBLIC_URL + "/compare"} component={Compare} />
                <Route path={process.env.PUBLIC_URL + "/checkout"} component={Checkout} />

                <Route path={process.env.PUBLIC_URL + "/authors"} component={AuthorsPage} />
                <Route path={process.env.PUBLIC_URL + "/publishers"} component={PublishersPage} />

                <Route path={process.env.PUBLIC_URL + "/not-found"} component={NotFound} />
                <Route path={process.env.PUBLIC_URL + "/privacyPolicy"} component={PrivacyPolicy} />
                <Route exact path="/logout">
                  <Redirect to="/" />
                </Route>
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  wishlistItems: state.wishlist.items,
  wishlistSuccess: state.wishlist.success,
  cartSuccess: state.cart.success,
});

export default connect(mapStateToProps)(multilanguage(App));
