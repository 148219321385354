import auth from "../reducers/auth.reducer";
import categories from "../reducers/categories.reducer";
import currencyData from "../reducers/currencyReducer";
import productData from "../reducers/productReducer";
import wishlist from "../reducers/wishlist.reducer";
import compareData from "../reducers/compareReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import publishers from "../reducers/publishers.reducer";
import authors from "../reducers/authors.reducer";
import products from "../reducers/products.reducer";
import cart from "../reducers/cart.reducers";
import orders from "../reducers/orders.reducer";
import reviews from "../reducers/review.reducer";
import banners from "../reducers/banners.reducer"

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  auth,
  categories,
  publishers,
  authors,
  products,
  cart,
  orders,
  reviews,
  banners,
  currencyData,
  productData,
  // cartData,
  wishlist,
  compareData,
});
// export default rootReducer;

export default (state, action) =>
  rootReducer(action.type === "LOGOUT_USER" ? undefined : state, action);
