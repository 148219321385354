import * as types from "../constants/types";
import Axios from "../api/index";

const fetchAuthorLoading = () => ({
  type: types.FETCH_AUTHOR_LIST_LOADING,
});

const fetchAuthorSuccess = (data) => ({
  type: types.FETCH_AUTHOR_LIST_SUCCESS,
  payload: data,
});

const fetchAuthorFailure = (error) => ({
  type: types.FETCH_AUTHOR_LIST_FAILURE,
  paylod: error,
});

export const selectAuthor = (author) => ({
  type: types.SELECTED_AUTHOR,
  payload: author,
});

export const fetchAuthors = (filterString, pagination = { pageNumber: 1, pageSize: 20 }) => (
  dispatch
) => {
  let url = "/authors/list";
  const paramsConfig = {
    ...pagination,
    filter: filterString,
  };
  const queryString = Object.keys(paramsConfig)
    .filter((key) => paramsConfig[key])
    .map((param) => `${param}=${paramsConfig[param]}`)
    .join("&");
  if (queryString) url += `?${queryString}`;
  console.log("AUTHORS URL =>", url);
  dispatch(fetchAuthorLoading());
  return Axios.get(url)
    .then((res) => {
      dispatch(fetchAuthorSuccess(res.data));
      return res;
    })
    .catch((err) => {
      dispatch(fetchAuthorFailure(err));
      throw err;
    });
};
