import * as types from "../constants/types";

const INITIAL_STATE = {
  loading: false,
  loadingMore: false,
  page: [],
  reviewsList: [],
  error: null,
  selectedReview: null,
};

const CategoryReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FETCH_REVIEWS_LOADING:
      return {
        ...state,
        loading: payload ? false : true,
        loadingMore: payload ? true : false,
        reviewsList: payload ? state.reviewsList : [],
        error: null,
      };
    case types.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        page: payload.page,
        reviewsList: [...state.reviewsList, ...payload.reviews],
        error: null,
      };
    case types.FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        reviewsList: [],
        error: payload,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
