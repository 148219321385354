import * as types from "../constants/types";

const INITIAL_STATE = {
  loading: false,
  loadingMore: false,
  productList: [],
  page: {},
  error: null,
  selectedProduct: null,
  filterConfig: [],
  filterConfigLoading: false,
  filterConfigError: null,
};

const ProductReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FETCH_PRODUCT_LIST_LOADING:
      return {
        ...state,
        loading: payload ? false : true,
        loadingMore: payload ? true : false,
        productList: payload ? state.productList : [],
        error: null,
      };
    case types.FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        page: payload.page,
        productList: [...state.productList, ...payload.products],
        error: null,
      };
    case types.FETCH_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        productList: [],
        error: payload,
      };
    case types.SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      };
    case types.FETCH_FILTER_CONFIG_LOADING:
      return {
        ...state,
        filterConfigLoading: true,
        filterConfigError: null,
        filterConfig: [],
      };
    case types.FETCH_FILTER_CONFIG_SUCCESS:
      return {
        ...state,
        filterConfigLoading: false,
        filterConfig: payload,
      };
    case types.FETCH_FILTER_CONFIG_FAILURE:
      return {
        ...state,
        filterConfigLoading: true,
        filterConfigError: payload,
      };
    default:
      return state;
  }
};

export default ProductReducer;
