import * as types from './../constants/types';

const INITIAL_STATE = {
    orderList: [],
    loading: false,
    error: null,
    page: {},
    deliveryAddress: null,
    selectedDeliveryAddress: null,
    orderReturn:[]
}

const ordersReducer = (state = INITIAL_STATE,{ type, payload }) => {
    switch (type) {
        case types.FETCH_ORDERS_LOADING:
        case types.FETCH_ORDER_RETURN_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                orderList: [],
                orderReturn:[]
            }
        case types.FETCH_ORDERS_SUCCESS:
        case types.FETCH_ORDER_RETURN_SUCCESS:
            return {
                ...state,
                loading: false,
                page: payload.page,
                orderList: payload.orders,
                orderReturn:payload,
            }
        case types.FETCH_ORDERS_FAILURE:
        case types.FETCH_ORDER_RETURN_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
                orderReturn:[]
            }
       case types.SAVE_DELIVERY_ADDRESS:
           return {
               ...state,
               deliveryAddress:payload
           }
           case types.SELECT_DELIVERY_ADDRESS:
            return {
                ...state,
                selectedDeliveryAddress:payload
            }

        default:
            return state;
    }
}

export default ordersReducer;