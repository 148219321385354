import * as types from "../constants/types";
import Axios from "../api/index";

const fetchPublisherLoading = () => ({
  type: types.FETCH_PUBLISHER_LIST_LOADING,
});

const fetchPublisherSuccess = (data) => ({
  type: types.FETCH_PUBLISHER_LIST_SUCCESS,
  payload: data,
});

const fetchPublisherFailure = (error) => ({
  type: types.FETCH_PUBLISHER_LIST_FAILURE,
  paylod: error,
});

export const selectPublisher = (publisher) => ({
  type: types.SELECTED_PUBLISHER,
  payload: publisher,
});

export const fetchPublishers = () => (dispatch) => {
  dispatch(fetchPublisherLoading());
  const url = "/publishers/list";
  return Axios.get(url)
    .then((res) => {
      dispatch(fetchPublisherSuccess(res.data));
      return res;
    })
    .catch((err) => {
      dispatch(fetchPublisherFailure(err));
      throw err;
    });
};
