// Auth Actions
export const SIGNUP_STARTED = "SIGNUP_STARTED";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_LOADING_STATE = "CLEAR_LOADING_STATE";
export const LOGOUT_USER = "LOGOUT_USER";
export const FETCH_PROFILE_LOADING = "FETCH_PROFILE_LOADING";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
// Select Address
export const SELECTED_ADDRESS = "SELECTED_ADDRESS";

// Categories Actions
export const FETCH_CATEGORY_LIST_LOADING = "FETCH_CATEGORY_LIST_LOADING";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILURE = "FETCH_CATEGORY_LIST_FAILURE";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";

// Banners Actions
export const FETCH_BANNER_LIST_LOADING = "FETCH_BANNER_LIST_LOADING";
export const FETCH_BANNER_LIST_SUCCESS = "FETCH_BANNER_LIST_SUCCESS";
export const FETCH_BANNER_LIST_FAILURE = "FETCH_BANNER_LIST_FAILURE";

//
export const SELECTED_BANNER = "SELECTED_BANNER";

// Brands Actions
export const FETCH_PUBLISHER_LIST_LOADING = "FETCH_PUBLISHER_LIST_LOADING";
export const FETCH_PUBLISHER_LIST_SUCCESS = "FETCH_PUBLISHER_LIST_SUCCESS";
export const FETCH_PUBLISHER_LIST_FAILURE = "FETCH_PUBLISHER_LIST_FAILURE";
export const SELECTED_PUBLISHER = "SELECTED_PUBLISHER";

// Brands Actions
export const FETCH_AUTHOR_LIST_LOADING = "FETCH_AUTHOR_LIST_LOADING";
export const FETCH_AUTHOR_LIST_SUCCESS = "FETCH_AUTHOR_LIST_SUCCESS";
export const FETCH_AUTHOR_LIST_FAILURE = "FETCH_AUTHOR_LIST_FAILURE";
export const SELECTED_AUTHOR = "SELECTED_AUTHOR";

// Products Actions
export const FETCH_PRODUCT_LIST_LOADING = "FETCH_PRODUCT_LIST_LOADING";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILURE = "FETCH_PRODUCT_LIST_FAILURE";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";

// Cart Actions
export const UPDATE_CART = "UPDATE_CART";
export const ADD_ITEM = "ADD_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const CLEAR_ITEM_FROM_CART = "CLEAR_ITEM_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const REHYDRATE = "REHYDRATE";

// WIshlist Actions
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const FETCH_WISHLIST_ITEMS_LOADING = "FETCH_WISHLIST_ITEMS_LOADING";
export const FETCH_WISHLIST_ITEMS_SUCCESS = "FETCH_WISHLIST_ITEMS_SUCCESS";
export const FETCH_WISHLIST_ITEMS_FAILURE = "FETCH_WISHLIST_ITEMS_FAILURE";

//Orders
export const FETCH_ORDERS_LOADING = "FETCH_ORDERS_LOADING";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";
export const SAVE_DELIVERY_ADDRESS = "SAVE_DELIVERY_ADDRESS";
export const SELECT_DELIVERY_ADDRESS = "SELECT_DELIVERY_ADDRESS";

// OrderReturn Actions
export const FETCH_ORDER_RETURN_LOADING = "FETCH_ORDER_RETURN_LOADING";
export const FETCH_ORDER_RETURN_SUCCESS = "FETCH_ORDER_RETURN_SUCCESS";
export const FETCH_ORDER_RETURN_FAILURE = "FETCH_ORDER_RETURN_FAILURE";

export const FETCH_CART_ITEMS_LOADING = "FETCH_CART_ITEMS_LOADING";
export const FETCH_CART_ITEMS_SUCCESS = "FETCH_CART_ITEMS_SUCCESS";
export const FETCH_CART_ITEMS_FAILURE = "FETCH_CART_ITEMS_FAILURE";

// Reviews
export const FETCH_REVIEWS_LOADING = "FETCH_REVIEWS_LOADING";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_FAILURE = "FETCH_REVIEWS_FAILURE";
export const PRESERVE_REVIEWS_DETAILS = "PRESERVE_REVIEWS_DETAILS";

export const FETCH_FILTER_CONFIG_LOADING = "FETCH_FILTER_CONFIG_LOADING";
export const FETCH_FILTER_CONFIG_SUCCESS = "FETCH_FILTER_CONFIG_SUCCESS";
export const FETCH_FILTER_CONFIG_FAILURE = "FETCH_FILTER_CONFIG_FAILURE";


