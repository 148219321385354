import * as types from "../constants/types";

const INITIAL_STATE = {
  loading: false,
  page: {},
  publisherList: [],
  error: null,
  selectedPublisher: null,
};

const PublisherReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FETCH_PUBLISHER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        publisherList: [],
        error: null,
      };
    case types.FETCH_PUBLISHER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        page: payload.page,
        publisherList: payload.publishers,
        error: null,
      };
    case types.FETCH_PUBLISHER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        publisherList: [],
        error: payload,
      };
    // case types.SELECTED_PUBLISHER:
    //   return {
    //     ...state,
    //     selectedPublisher: payload,
    //   };

    default:
      return state;
  }
};

export default PublisherReducer;
