import * as types from "./../constants/types";

export const cartItemsTotalPrice = (items, coupon = null) => {
  let total = items.reduce((price, product) => {
    if (product.sale_price) {
      return price + product.sale_price * product.quantityCount;
    }
    return price + product.price * product.quantityCount;
  }, 0);
  const discount = coupon ? (total * Number(coupon.discountInPercent)) / 100 : 0;

  return total - discount;
};

// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
  const existingCartItemIndex = state.items.findIndex((item) => item._id === action.payload._id);

  if (existingCartItemIndex > -1) {
    const newState = [...state.items];
    newState[existingCartItemIndex].quantityCount += action.payload.quantityCount;
    return newState;
  }
  return [...state.items, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
  return state.items.reduce((acc, item) => {
    if (item._id === action.payload._id) {
      const newquantityCount = item.quantityCount - action.payload.quantityCount;

      return newquantityCount > 0
        ? [...acc, { ...item, quantityCount: newquantityCount }]
        : [...acc];
    }
    return [...acc, item];
  }, []);
};

const updateItemInCart = (state, action) => {
  const updatedProduct = state.items.map((item) => {
    if (item._id === action.payload._id) {
      return { ...item, ...action.payload };
    } else return item;
  });
  return updatedProduct;
};

const clearItemFromCart = (state, action) => {
  return state.items.filter((item) => item._id !== action.payload._id);
};

const INITIAL_STATE = {
  isOpen: false,
  items: [],
  coupon: null,
  loading: false,
  success: false,
};

export const CartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REHYDRATE:
      return { ...state, ...action.payload };
    case types.UPDATE_CART:
      return { ...state, items: action.payload };
    case types.ADD_ITEM:
      // const items = addItemToCart(state, action);
      // console.log("ADD_ITEM", items)
      return { ...state, items: addItemToCart(state, action) };
    case types.UPDATE_ITEM:
      console.log("ADD_ITEM");
      return { ...state, items: updateItemInCart(state, action) };
    case types.REMOVE_ITEM:
      return { ...state, items: removeItemFromCart(state, action) };
    case types.CLEAR_ITEM_FROM_CART:
      return { ...state, items: clearItemFromCart(state, action) };
    case types.CLEAR_CART:
      return { ...state, items: [] };
    case types.FETCH_CART_ITEMS_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.FETCH_CART_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        success: true,
      };
    case types.FETCH_CART_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default CartReducer;
