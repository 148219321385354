import * as types from "../constants/types";

const INITIAL_STATE = {
  loading: false,
  categoriesList: [],
  error: null,
  selectedCategory: null,
};

const CategoryReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FETCH_CATEGORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        categoriesList: [],
        error: null,
      };
    case types.FETCH_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriesList: payload,
        error: null,
      };
    case types.FETCH_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        categoriesList: [],
        error: payload,
      };
    case types.SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };

    default:
      return state;
  }
};

export default CategoryReducer;
